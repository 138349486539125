import React, { useContext, useEffect, useState } from "react";
import { GMCContext } from "../../GMCContext";
import axios from "axios";
import Pagination from "../Comman/Pagination";
import Copy from "../Comman/Copy";

function Team({ address }) {
  const { formatAddress, web3, contract, formatDateTime } =
    useContext(GMCContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [search, setSearch] = useState("");
  const [level, setlevel] = useState(1);


  const getData = async () => {
    try {
      setLoading(true);
      await axios
        .post(process.env.REACT_APP_BACKEND_LINK, {
          method: "user",
          submethod: "level",
          key: process.env.REACT_APP_KEY,
          address,
          level,
          page: currentPage,
          pageSize: pageSize,
        })
        .then((res) => {
          setLoading(false);
          // console.log("API Response:", res.data); // Added log
          if (res.data.error) {
            return "";
          }
          setdata(res.data.data);
          setPages(Math.ceil(res.data.dataLength / pageSize));
          // console.log("Data set successfully", res.data.data); // Added log
        });
    } catch (err) {
      console.log("error in team : ", err);
      setLoading(false);
    }
  };
  // console.log("data:",data) 
  useEffect(() => {
    if (address) {
      getData();
    }
  }, [address, pages, pageSize, currentPage]);

  useEffect(() => {
    if (level) {
      setCurrentPage(1);
    }
    getData();
  }, [level]);

  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="form-head d-flex align-items-center">
                <div className="input-group search-area d-inline-flex m-3">
                  <input
                    type="text"
                    className="form-control form-control-rev pe-0"
                    placeholder="Search Level"
                    value={level}
                    onChange={(e) => setlevel(Number(e.target.value))}
                  />
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="input-group-text input-group-text-rev "
                      onClick={() => setlevel("")}
                    >
                      {level ? (
                        <img
                          src="../assets/images/close.png"
                          alt=""
                          style={{ height: "30px" }}
                        />
                      ) : (
                        <i className="flaticon-381-search-2" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <h3 className="py-3 px-5">Team</h3>
              <hr />
              <div className="table-responsive">
                <table
                  id="example5"
                  className="table table-striped patient-list mb-4 dataTablesCard fs-14"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Date</th>
                      <th>Address</th>
                      <th>Referrl Address</th>
                      <th>Total Staking</th>
                      <th>Balance</th>
                      <th>Direct Team</th>
                      <th>Direct Income</th>
                      <th>Rank Income</th>
                      <th>Rank</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data.length
                      ? data.map((e, i) => {
                        let ind = currentPage * pageSize - pageSize + (i + 1);
                        return (
                          <tr key={ind}>
                            <td>{ind}</td>
                            <td>{e.createdAt}</td>
                            <td>
                              <a
                                target="_blank"
                                className="text-primary"
                                href={`${process.env.REACT_APP_EXPLORER}/address/${e.address}`}
                                rel="noreferrer"
                              >
                                {formatAddress(e.address)}
                              </a>
                              {e.address ? <Copy data={e.address} /> : ""}
                            </td>
                            <td>
                              <a
                                target="_blank"
                                className="text-primary"
                                href={`${process.env.REACT_APP_EXPLORER}/address/${e.ref_address}`}
                                rel="noreferrer"
                              >
                                {formatAddress(e.ref_address)}
                              </a>
                              {e.ref_address ? <Copy data={e.ref_address} /> : ""}
                            </td>
                            <td>
                              <span>${e.t_staking ? e.t_staking : "0"}</span>
                            </td>
                            <td>
                            <span>${!isNaN(e.dbbalance) ? e.dbbalance : "0"}</span>
                            </td>
                            <td>${e.team ? e.team : "0"}</td>
                            <td>${e.rank_rew ? e.rank_rew : "0"}</td>
                            <td>${e.level_inc ? e.level_inc : "0"}</td>
                            <td>${e.rank ? e.rank : "0"}</td>
                            {/* <td>${e.team ? e.team : "0"}</td>
                            <td>${e.team ? e.team : "0"}</td> */}
                            {/* <td>{e.levelONE ? e.levelONE : "-"}</td> */}
                            {/* <td>
                              <div className="d-flex align-items-center">
                                {e.status === true ? (
                                  <>
                                    <div className="green-dot-active mx-2"></div>
                                    Active
                                  </>
                                ) : (
                                  <>
                                    <div className="red-dot-active mx-2"></div>
                                    Deactive
                                  </>
                                )}
                              </div>
                            </td> */}
                          </tr>
                        );
                      })
                      : ""}
                  </tbody>
                </table>
                {loading ? (
                  <div className="text-center">
                    <p>Loading...</p>
                  </div>
                ) : !data || !data.length ? (
                  <div className="text-center">
                    <p>No data found.</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                pages={pages}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Team;
