import React, { createContext, useEffect, useState } from "react";
import Web3 from "web3";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import copy from "copy-to-clipboard";
import {
  createWeb3Modal,
  defaultConfig,
  useDisconnect,
  useSwitchNetwork,
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { ethers } from "ethers";

export const GMCContext = createContext();
const web3 = new Web3(process.env.REACT_APP_RPC);

const projectId = process.env.REACT_APP_PROJECT_ID;
const mainnet = [
  {
    chainId: 56,
    name: "BSC Mainnet",
    currency: "BNB",
    explorerUrl: "https://bscscan.com",
    rpcUrl: "https://bsc-dataseed.binance.org",
  },
];

const metadata = {
  name: "My Website",
  description: "My Website description",
  url: "http://localhost:3000", // origin must match your domain & subdomain
  icons: ["https://avatars.mywebsite.com/"],
};

createWeb3Modal({
  ethersConfig: defaultConfig({
    metadata,
    defaultChainId: mainnet[0].chainId,
    enableEIP6963: true,
    enableInjected: true,
    enableCoinbase: true,
    rpcUrl: "...", // used for the Coinbase SDK
  }),
  chains: mainnet,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
});

function GMCcontext({ children }) {
  const [USER, setUSER] = useState([]);
  const [balance, setBalance] = useState(0);
  const [totalMembers, setTotalMembers] = useState(0);
  const [settings, setsettings] = useState(null);
  const [dashd, setDashd] = useState([]);
  const [dashdgold, setDashdgold] = useState([]);
  const [dashdcontract, setDashdcontract] = useState([]);
  const [userdata, setUserdata] = useState([]);
  const [tokenRate, setTokenRate] = useState(0);
  const [gold, setGold] = useState(0);
  const [contract, setContract] = useState([]);
  const [toggleAchieveRank, setToggleAchieveRank] = useState("");

  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { disconnect } = useDisconnect();
  const { switchNetwork } = useSwitchNetwork();

  const [maticBalance, setMaticBalance] = useState(0);
  const [paraBtoken, setParaBtoken] = useState(0);
  const [rdpkg, setrdpkg] = useState([]);
  const [fdpkg, setfdpkg] = useState([]);
  const [rate, setRate] = useState(0);
  const [esigner, setSigner] = useState(null);
  const [dbbalance, setDbbalance] = useState(0);
  const [dbuser, setDbuser] = useState(null);
  const [dashD, setdashD] = useState(null);
  const [stakcontract, setStakcontract] = useState(null);
  const [reloadData, setReloadData] = useState(false);
  const { open } = useWeb3Modal();
  const { walletProvider } = useWeb3ModalProvider();

  const [isClassToggled, setIsClassToggled] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [account, setAccount] = useState(address);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");

  const [token, setToken] = useState(localStorage.getItem("mark#321") || "");
  const reload = () => setReloadData(!reloadData);

  const connectInjected = async () => {
    try {
      await open();
    } catch (ex) {
      console.log(ex);
    }
  };

  const toastError = (data) => toast.error(data);
  const toastSuccess = (data) => toast.success(data);
  const toastInfo = (data) =>
    toast(data, {
      icon: "❕",
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    });

  const copyAddress = (address) => {
    copy(address);
    toastSuccess("Copied");
  };

  const disconnectNow = async () => {
    try {
      disconnect();
      localStorage.setItem("isconnected", false);
      setBalance(0);
    } catch (ex) {
      console.log(ex);
    }
  };

  const connectMetamask = async () => {
    try {
      open();
      localStorage.setItem("isconnected", true);
    } catch (error) {
      alert(error);
    }
  };

  const getUserData = async (address) => {
    return await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
      method: "user",
      submethod: "getbyid",
      key: process.env.REACT_APP_KEY,
      address: address ? address.toLowerCase() : address,
    });
  };

  function formatDateTime(date) {
    const datetime = new Date(date);
    const day = String(datetime.getDate()).padStart(2, "0");
    const month = String(datetime.getMonth() + 1).padStart(2, "0");
    const year = datetime.getFullYear();
    const hours = String(datetime.getHours()).padStart(2, "0");
    const minutes = String(datetime.getMinutes()).padStart(2, "0");
    const seconds = String(datetime.getSeconds()).padStart(2, "0");

    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  }

  const getUserDB = async () => {
    try {
      const data = await getUserData(account);
      if (data.data.error) {
        setDbuser(null);
        return "";
      }
      setDbuser(data.data.data);
      const dap = data.data.data;
      const fbln =
        dap.level_inc + dap.rank_inc + dap.retirement_inc - dap.t_withdrawal;
      setDbbalance(Number(fbln).toFixed(2));
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    getUserDB();
  }, [account, reloadData]);

  useEffect(() => {
    getSetting();
  }, []);

  useEffect(() => {
    setAccount(address);
  }, [address]);

  const getSigner = async () => {
    if (!isConnected) return;

    try {
      const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
      const signer = await ethersProvider.getSigner();
      setSigner(signer);
      const balance = await ethersProvider.getBalance(account);
      setMaticBalance(Number(ethers.utils.formatUnits(balance, 18)).toFixed(2));
    } catch (error) {
      console.error("Error getting signer or balance:", error);
    }
  };

  const getSMCS = async () => {
    if (!isConnected || !settings || esigner === null) return;

    let tokenAbi = [];
    if (settings.token_abi) {
      try {
        tokenAbi = JSON.parse(settings.token_abi);
      } catch (error) {
        console.error("Invalid token ABI JSON:", error);
        return;
      }
    } else {
      console.error("Token ABI is undefined");
      return;
    }

    let contractAbi = [];
    if (settings.contract_abi) {
      try {
        contractAbi = JSON.parse(settings.contract_abi);
      } catch (error) {
        console.error("Invalid contract ABI JSON:", error);
        return;
      }
    } else {
      console.error("Contract ABI is undefined");
      return;
    }

    try {
      let contract = new ethers.Contract(settings.token, tokenAbi, esigner);
      let mainContractWrite = new ethers.Contract(
        settings.contract,
        contractAbi,
        esigner
      );
      setStakcontract(mainContractWrite);

      let balance = await contract.balanceOf(account);
      let PRBbalance = await contract.balanceOf(settings.para_banking_contract);
      console.log("PRBbalance", PRBbalance.toString());
      setBalance(Number(ethers.utils.formatUnits(balance, 8)).toFixed(2));
      setParaBtoken(Number(ethers.utils.formatUnits(PRBbalance, 8)).toFixed(2));
    } catch (error) {
      console.error("Error getting SMCS data:", error);
    }
  };

  useEffect(() => {
    getSMCS();
  }, [isConnected, account, walletProvider, settings, esigner, reloadData]);

  useEffect(() => {
    getSigner();
  }, [isConnected, account, walletProvider]);

  const formatAddress = (address) => {
    if (address) {
      return address.substr(0, 6) + "..." + address.substr(-6, 6);
    }
  };

  const copyText = (data) => copy(data);

  const getSetting = async () => {
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "setting",
        submethod: "get",
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        // console.log("setting :", res.data);
        setsettings(res.data.data);
      });
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "rdlist",
        submethod: "get",
        search: search,
        page: currentPage,
        pageSize: pageSize,
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          console.log("error :", res.data.error);
          return "";
        }
        console.log("rdlist :", res.data);
        setrdpkg(res.data.data);
      });
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "fdlist",
        submethod: "get",
        search: search,
        page: currentPage,
        pageSize: pageSize,
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        console.log("fdlist :", res.data);
        setfdpkg(res.data.data);
      });
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "user",
        submethod: "dashd",
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        // console.log("dash :", res.data);
        setdashD(res.data.data);
      });
  };

  const getDashData = async () => {
    try {
      const res = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
        method: "user",
        submethod: "dashd",
        key: process.env.REACT_APP_KEY,
      });
      if (res.data.error) {
        return "";
      }

      setDashd(res.data.data);
      setDashdgold(res.data.gold);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  useEffect(() => {
    getDashData();
  }, []);

  // console.log("rdpkg :", rdpkg);
  // console.log("fdpkg :", fdpkg);

  return (
    <>
      <GMCContext.Provider
        value={{
          settings,
          web3,
          balance,
          userdata,
          getUserDB,
          copyText,
          getSMCS,
          gold,
          USER,
          tokenRate,
          formatDateTime,
          formatAddress,
          contract,
          totalMembers,
          dashd,
          dashdcontract,
          dashdgold,
          toggleAchieveRank,
          setToggleAchieveRank,

          library: ethers,
          maticBalance,
          dbuser,
          esigner,
          account: account ? account.toLowerCase() : account,
          setAccount,
          connectInjected,
          disconnectNow,
          connectMetamask,
          fdpkg,
          rdpkg,
          toastError,
          toastSuccess,
          getUserData,
          dbbalance,
          toastInfo,
          ethers,
          rate,
          dashD,
          copyAddress,
          paraBtoken,
          stakcontract,
          reload,
          reloadData,
          switchNetwork,
          isClassToggled,
          setIsClassToggled,
          showMenu,
          setShowMenu,
          token,
          setToken,
        }}
      >
        {children}
      </GMCContext.Provider>
      <Toaster />
    </>
  );
}

export default GMCcontext;
