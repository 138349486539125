import React, { useEffect, useState } from "react";
import Sidebar from "../Comman/Sidebar";
import Header from "../Comman/Header";
import Footer from "../Comman/Footer";
import axios from "axios";
import Web3 from "web3";
import { Link } from "react-router-dom";
const web3 = new Web3(process.env.REACT_APP_RPC);

export default function Index() {
  const [dashboardData, setDashboardData] = useState(null);
  const [settingData, setSettingData] = useState(null);
  const [ownerContractBal, setOwnerContractBal] = useState(0);
  const getDashboardData = async () => {
    try {
      await axios
        .post(process.env.REACT_APP_BACKEND_LINK, {
          method: "user",
          submethod: "dashd",
          key: process.env.REACT_APP_KEY,
        })
        .then((res) => {
          // console.log("res.data:", res.data);
          setDashboardData(res.data.data); // Assuming this sets the state for dashboard data
          if (res.data.error) {
            return ""; // You might want to handle this case better
          }
        });
    } catch (err) {
      console.log("error : ", err);
    }
  };


  // console.log("dashboardData:", dashboardData)

  const getSettingData = async () => {
    try {
      await axios
        .post(process.env.REACT_APP_BACKEND_LINK, {
          method: "setting",
          submethod: "get",
          key: process.env.REACT_APP_KEY,
        })
        .then((res) => {
          // console.log("res.data", res.data);
          if (res.data.error) {
            console.log("res.data.error", res.data.error);
          } else {
            setSettingData(res.data.data);
          }
        });
    } catch (err) {
      console.log("error : ", err);
    }
  };
  // console.log("settingData:",settingData)
  const updateSettingData = async () => {
    try {
      await axios
        .post(process.env.REACT_APP_BACKEND_LINK, {
          method: "setting",
          submethod: "update",
          key: process.env.REACT_APP_KEY,
          status: !settingData.withdrawal,
        })
        .then((res) => {
          // console.log("res.data", res.data);
          if (res.data.error) {
            console.log("res.data.error", res.data.error);
          } else {
            getSettingData();
          }
        });
    } catch (err) {
      console.log("error : ", err);
    }
  };

  useEffect(() => {
    getDashboardData();
    getSettingData();
  }, []);

  const getContractBalance = async () => {
    try {
      if (settingData?.contract && settingData?.contract_abi) {
        const balwei = await web3.eth.getBalance(
          process.env.REACT_APP_AFFILATE_OWNER
        );
        const bal = await web3.utils.fromWei(balwei, "ether");
        setOwnerContractBal(bal);
      }
    } catch (err) {
      console.log("error : ", err);
    }
  };

  useEffect(() => {
    getContractBalance();
  }, [settingData]);

  return (
    <>
      <div id="main-wrapper">
        <Header />
        <Sidebar />
        <div className="content-body">
          <div className="container-fluid">
            <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
              <div className="me-auto d-none d-lg-block">
                <h3 className="text-black font-w600">Welcome to PROFIT PLUS!</h3>
              </div>
              {/* <div>
                <Link
                  className="nav-item w-50"
                  to="/stake"
                  aria-expanded="false"
                >
                  <button className="d-flex align-items-center btn btn-primary ">
                    Deposit
                  </button>
                </Link>
              </div> */}
            </div>
            <div className="row">
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Withdrawal Status</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {settingData ? settingData?.withdrawal_status === true ? "Enabled" : "Disabled" : ""}

                            <span className="fs-12">
                              <button
                                type="button"
                                className={`btn btn-${settingData?.withdrawal_status === true
                                  ? "success" : "danger"
                                  } btn-sm p-1 px-2 mx-2`}
                                onClick={() => updateSettingData()}
                              >
                                <i
                                  className={`fa fa-${settingData?.withdrawal_status === true
                                    ? "check"
                                    : "x"
                                    } fs-18`}
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </span>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Total Users</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {dashboardData ? dashboardData.t_users : "0"} 
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Total Staking</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            $ {dashboardData ? dashboardData.t_staking : "0"} 
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Direct Reward</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            $ {dashboardData ? dashboardData.direct_rew : "0"}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Salary Reward</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                         $ {dashboardData ? dashboardData[0]?.Salary : "0"}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Total Matching</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            $ {dashboardData ? dashboardData.t_matching : "0"}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Performance Reward</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            $ {dashboardData ? dashboardData.p_income : "0"}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Total Withdrwal</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                           $ {dashboardData ? dashboardData.t_withdrawal : "0"}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Total Withdrwal</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                           $ {dashboardData ? dashboardData.withdrawal : "0"}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Total Pending Withdrwal</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                           $ {dashboardData ? dashboardData.p_withdrwal_amount : "0"}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Total Payid Withdrwal</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {dashboardData ? dashboardData[0]?.rank : "0"}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Daily Reward</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                          $ {dashboardData ? dashboardData[0]?.t_DReward : "0"}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Level Reward</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                          $ {dashboardData ? dashboardData[0]?.t_LevelReward : "0"}                        
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
           
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Reward</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                           $ {dashboardData ? dashboardData[0]?.t_Reward : "0"}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Bonanza Reward </p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                          {dashboardData ? dashboardData[0]?.bonanza : "0"}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/*   <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">4X(Users)</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {dashboardData?.user4x ? dashboardData?.user4x : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">6X(Users)</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {dashboardData?.user6x ? dashboardData?.user6x : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Total BNB</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {dashboardData?.bnbs ? dashboardData?.bnbs : 0} BNB
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Total Withdrawal</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {dashboardData?.withdrawal
                              ? dashboardData?.withdrawal
                              : 0}{" "}
                            {process.env.REACT_APP_TICKER}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Total Withdrawal($)</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            $
                            {dashboardData?.withdrawalusd
                              ? dashboardData?.withdrawalusd
                              : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Contract Balance</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {ownerContractBal ? ownerContractBal : 0} BNB
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Total Restake</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            ${" "}
                            {dashboardData?.totalrestake
                              ? dashboardData?.totalrestake
                              : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Restake Users</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {dashboardData?.intotalrestakeusers
                              ? dashboardData?.intotalrestakeusers
                              : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">User Smart Contract</p>
                        <div className="d-flex flex-wrap">
                          {!loading ? (
                            <button
                              type="button"
                              className="btn btn-primary btn-sm"
                              data-bs-toggle="modal"
                              data-bs-target="#confirmUpd"
                            >
                              <i className="fa fa-plus " aria-hidden="true" />
                            </button>
                          ) : (
                            ""
                          )}
                          <div>{loading ? "Data updating....." : ""}</div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            {/* <GoldRequest /> */}
          </div>
        </div>
        {/* <div className="modal fade" id="confirmUpd">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Are you sure??</h5>
                <div className="modal-footer">
                  <button type="button" className="btn btn-danger light" data-bs-dismiss="modal">
                    Close
                  </button>
                  <button type="button" className="btn btn-primary" onClick={() => getCheck()} data-bs-dismiss="modal">
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <Footer />
      </div>
    </>
  );
}
