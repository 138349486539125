import React, { useContext, useEffect, useState } from "react";
import { GMCContext } from "../../GMCContext";
import axios from "axios";
import Pagination from "../Comman/Pagination";
import Header from "../Comman/Header";
import Sidebar from "../Comman/Sidebar";
import Copy from "../Comman/Copy";
import Footer from "../Comman/Footer";

export default function MatchingRewardSidbar({ address }) {
    const { formatAddress, web3, contract, formatDateTime } =
        useContext(GMCContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);
    const [data, setdata] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");

    const getData = async (address) => {
        // console.log('address', address)
        try {
            setLoading(true);
            const res = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
                method: "binary",
                submethod: "get",
                key: process.env.REACT_APP_KEY,
                address,
                search,
                page: currentPage,
                pageSize: pageSize,
            });
            // console.log('res', res)
            setLoading(false);
            if (res.data.error) {
                return "";
            }
            setdata(res.data.data);
            setPages(Math.ceil(res.data.dataLength / pageSize));
            // console.log("data", res.data.data); 
        } catch (err) {
            console.log("error:", err);
        } finally {
            setLoading(false);
        }
    };
    //   console.log("data:",data)
    useEffect(() => {
        getData();
    }, [pages, pageSize, currentPage]);

    useEffect(() => {
        if (search) {
            setCurrentPage(1);
        }
        getData();
    }, [search]);
    return (
        <>
            <div id="main-wrapper">
                <Header />
                <Sidebar />
                <div className="content-body">
                    <div className="container-fluid">
                        <div className="page-titles">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/dashboard">Dashboard</a>
                                </li>
                                <li className="breadcrumb-item active text-primary">
                                    Matching  Reward
                                </li>
                            </ol>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="form-head d-flex align-items-center">
                                            <div className="input-group search-area d-inline-flex m-3">
                                                <input
                                                    type="text"
                                                    className="form-control form-control-rev pe-0"
                                                    placeholder="Search here"
                                                    value={search}
                                                    onChange={(e) => setSearch(e.target.value)}
                                                />
                                                <div className="input-group-append">
                                                    <button
                                                        type="button"
                                                        className="input-group-text input-group-text-rev "
                                                        onClick={() => setSearch("")}
                                                    >
                                                        {search ? (
                                                            <img
                                                                src="./assets/images/close.png"
                                                                alt=""
                                                                style={{ height: "30px" }}
                                                            />
                                                        ) : (
                                                            <i className="flaticon-381-search-2" />
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <h3 class="py-3 px-5">Matching Reward</h3>
                                        <hr />
                                        <div className="table-responsive">
                                            <table
                                                id="example5"
                                                className="table table-striped patient-list mb-4 dataTablesCard fs-14"
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Address</th>
                                                        <th>Left Business Matching</th>
                                                        <th>Right Business Matching</th>
                                                        <th>Reward</th>
                                                        <th>Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data && data?.length
                                                        ? data.map((e, i) => {
                                                            let ind =
                                                                currentPage * pageSize - pageSize + (i + 1);
                                                            return (
                                                                <tr>
                                                                    <td>{ind}</td>
                                                                    <td>
                                                                        <a
                                                                            target="_blank"
                                                                            className="text-primary"
                                                                            href={`${process.env.REACT_APP_EXPLORER}/address/${e.address}`}
                                                                            rel="noreferrer"
                                                                        >
                                                                            {formatAddress(e.address)}
                                                                        </a>
                                                                        {e.address ? <Copy data={e.address} /> : ""}
                                                                    </td>
                                                                    <td>
                                                                        <span>{e.left_leg ? e.left_leg : "0"}</span>
                                                                    </td>
                                                                    <td>
                                                                        <span>{e.right_leg ? e.right_leg : "0"}</span>
                                                                    </td>
                                                                    <td> <span>${e.income ? e.income : "0"}</span> </td>
                                                                    <td>{e.createdAt}</td>
                                                                </tr>
                                                            );
                                                        })
                                                        : ""}
                                                </tbody>
                                            </table>
                                            {loading ? (
                                                <div className="text-center">
                                                    <p>Loading...</p>
                                                </div>
                                            ) : !data || !data.length ? (
                                                <div className="text-center">
                                                    <p>No data found.</p>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                        <Pagination
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            pageSize={pageSize}
                                            setPageSize={setPageSize}
                                            pages={pages}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}
