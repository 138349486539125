import React, { useContext, useEffect, useState } from "react";
import { GMCContext } from "../../GMCContext";
import axios from "axios";
import Pagination from "../Comman/Pagination";
import Copy from "../Comman/Copy";

function SponserRewardReport({ address }) {
    const { formatAddress, web3, contract, formatDateTime } =
        useContext(GMCContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);
    const [data, setdata] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");

    // const getData = async (address) => {
    //   try {
    //     setLoading(true);
    //     await axios
    //       .post(process.env.REACT_APP_BACKEND_LINK, {
    //         method: "performance",
    //         submethod: "getbyid",
    //         key: process.env.REACT_APP_KEY,
    //         address,
    //         search,
    //         page: currentPage,
    //         pageSize: pageSize,
    //       })
    //       .then((res) => {
    //         // console.log('res.data', res.data)
    //         setLoading(false);
    //         if (res.data.error) {
    //           return "";
    //         }
    //         setdata(res.data.data);
    //         setPages(Math.ceil(res.data.dataLength / pageSize));
    //       });
    //   } catch (err) {
    //     console.log("error : ", err);
    //     setLoading(false);
    //   }
    // };
    // console.log('data', data)
    // useEffect(() => {
    //   if (address) {
    //     getData(address);
    //   }
    // }, [address, pages, pageSize, currentPage]);

    const getData = async (address) => {
        console.log('address', address)
        try {
            setLoading(true);
            const res = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
                method: "direct",
                submethod: "getbyid",
                key: process.env.REACT_APP_KEY,
                address,
                search,
                page: currentPage,
                pageSize: pageSize,
            });
            // console.log('res', res)
            setLoading(false);
            if (res.data.error) {
                return "";
            }
            setdata(res.data.data);
            setPages(Math.ceil(res.data.dataLength / pageSize));
            // console.log("data", res.data.data); // Log after setting data
        } catch (err) {
            console.log("error:", err);
        } finally {
            setLoading(false); // Always called regardless of success or error
        }
    };
    // console.log('data', data)
    useEffect(() => {
        if (address) {
            setCurrentPage(1); // Reset page to 1 if search changes
            getData(address);
        }
    }, [address, search, pageSize, currentPage]); // Single useEffect to manage data fetch


    useEffect(() => {
        if (search) {
            setCurrentPage(1);
        }
        getData();
    }, [search]);
    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="form-head d-flex align-items-center">
                                <div className="input-group search-area d-inline-flex m-3">
                                    <input
                                        type="text"
                                        className="form-control form-control-rev pe-0"
                                        placeholder="Search here"
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                    <div className="input-group-append">
                                        <button
                                            type="button"
                                            className="input-group-text input-group-text-rev "
                                            onClick={() => setSearch("")}
                                        >
                                            {search ? (
                                                <img
                                                    src="../assets/images/close.png"
                                                    alt=""
                                                    style={{ height: "30px" }}
                                                />
                                            ) : (
                                                <i className="flaticon-381-search-2" />
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <h3 class="py-3 px-5">Sponser Reward </h3>
                            <hr />
                            <div className="table-responsive">
                                <table
                                    id="example5"
                                    className="table table-striped patient-list mb-4 dataTablesCard fs-14"
                                >
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>FROM</th>
                                            <th>Reward</th>
                                            <th>Staking</th>
                                            <th>Staking(%)</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data && data?.length
                                            ? data.map((e, i) => {
                                                let ind =
                                                    currentPage * pageSize - pageSize + (i + 1);
                                                return (
                                                    <tr>
                                                        <td>{ind}</td>
                                                        <td>
                                                            <a
                                                                target="_blank"
                                                                className="text-primary"
                                                                href={`${process.env.REACT_APP_EXPLORER}/address/${e.from}`}
                                                                rel="noreferrer"
                                                            >
                                                                {formatAddress(e.from)}
                                                            </a>
                                                            {e.from ? <Copy data={e.from} /> : ""}
                                                        </td>
                                                        <td> <span>$ {e.income ? e.income : "0"}</span> </td>
                                                        <td>
                                                            <span>$ {e.staking ? e.staking : "0"}</span>
                                                        </td>
                                                        <td>
                                                            <span>({e.income_per ? e.income_per : "0"}%)</span>
                                                        </td>
                                                        <td>{e.createdAt}</td>
                                                    </tr>
                                                );
                                            })
                                            : ""}
                                    </tbody>
                                </table>
                                {loading ? (
                                    <div className="text-center">
                                        <p>Loading...</p>
                                    </div>
                                ) : !data || !data.length ? (
                                    <div className="text-center">
                                        <p>No data found.</p>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                            <Pagination
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                pages={pages}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SponserRewardReport;
