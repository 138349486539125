import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { GMCContext } from "../../GMCContext";

export default function Header() {
    const { token, pWithdrawal, setPWithdrawal, pwtoken, fromLocation } = useContext(GMCContext);
    const [showMenu, setShowMenu] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

  const logOut = async () => {
    try {
        console.log("logout");

        // Retrieve user data from localStorage
        let userData = localStorage.getItem("user1f2s24dsaf7");
        
        // Check if userData exists
        if (!userData) {
            console.warn("No user data found in localStorage.");
            localStorage.clear();
            navigate("/");
            return;  // Exit the function since there's no user data to process
        }

        // Parse the user data
        let user = JSON.parse(userData);

        // Make the API call to logout
        const response = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
            method: "logout",
            submethod: "insert",
            key: process.env.REACT_APP_KEY,
            username: user.username,
            _id: user.id,
        });

        const { resCode } = response.data;
        console.log('response.data', response.data);

        // If logout is successful, clear localStorage and navigate
        if (resCode === 200) {
            localStorage.clear();
            navigate("/");
        }

    } catch (err) {
        console.error("Error inserting data:", err);
    }
};


useEffect(() => {
    // if (location.pathname != "/pending-withdrawal") {
    if (location.pathname != fromLocation) {
        // setPWithdrawal(false);
        localStorage.removeItem("2faAuth");
    }
}, []);
  return (
    <>
      <div className="nav-header">
        <a href="/" className="brand-logo">
          <img src="/assets/images/logo192.png" width={40} alt="" srcset="" />
          <h2 className="mb-0 d-md-block d-none">PROFIT PLUS</h2>
        </a>
        <div className="nav-control">
          <div className="hamburger">
            <span className="line" />
            <span className="line" />
            <span className="line" />
          </div>
        </div>
      </div>
      <div className="header">
        <div className="header-content">
          <nav className="navbar navbar-expand">
            <div className="collapse navbar-collapse justify-content-between">
              <div className="header-left">
                <div className="dashboard_bar">PROFIT PLUS ADMIN</div>
              </div>
              <ul className="navbar-nav header-right">
                <li className="nav-item dropdown header-profile">
                  <a
                    className="nav-link"
                    role="button"
                    data-bs-toggle="dropdown"
                  >
                    <img src="/assets/images/logo70.png" width={20} alt="" />
                    {/* <div className="header-info">
                      <span>
                        Hello,<strong> ONMAX</strong>
                      </span>
                    </div> */}
                  </a>
                  <div className="dropdown-menu dropdown-menu-end">
                    <a
                      className="dropdown-item ai-icon"
                      type="button"
                      onClick={() => logOut()}
                    >
                      <svg
                        id="icon-logout"
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-danger"
                        width={18}
                        height={18}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                        <polyline points="16 17 21 12 16 7" />
                        <line x1={21} y1={12} x2={9} y2={12} />
                      </svg>
                      <span className="ms-2">Logout </span>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}
