import React, { useContext, useEffect, useState } from "react";
import { GMCContext } from "../../GMCContext";
import axios from "axios";
import Pagination from "../Comman/Pagination";
import Header from "../Comman/Header";
import Sidebar from "../Comman/Sidebar";
import Copy from "../Comman/Copy";
import Footer from "../Comman/Footer";


export default function TwoHundredFifty({address}) {
    const { formatAddress, web3, contract, formatDateTime } =
    useContext(GMCContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const getData = async () => {
    try {
      setLoading(true);
      await axios
        .post(process.env.REACT_APP_BACKEND_LINK, {
          method: "transaction",
          submethod: "get250",
          key: process.env.REACT_APP_KEY,
          search: search,
          page: currentPage,
          pageSize: pageSize,
        })
        .then((res) => {
          setLoading(false);
          if (res.data.error) {
            return "";
          }
          setdata(res.data.data);
          setPages(Math.ceil(res.data.dataLength / pageSize));
        });
    } catch (err) {
      console.log("error : ", err);
      setLoading(false);
    }
  };
  // console.log("data8465456:",data)
  useEffect(() => {
    getData();
  }, [pages, pageSize, currentPage]);

  useEffect(() => {
    if (search) {
      setCurrentPage(1);
    }
    getData();
  }, [search]);
  return (
    <>
    <div id="main-wrapper">
      <Header />
      <Sidebar />
      <div className="content-body">
        <div className="container-fluid">
          <div className="page-titles">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Dashboard</a>
              </li>
              <li className="breadcrumb-item active text-primary">
             250 Staking
              </li>
            </ol>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body p-0">
                  <div className="form-head d-flex align-items-center">
                    <div className="input-group search-area d-inline-flex m-3">
                      <input
                        type="text"
                        className="form-control form-control-rev pe-0"
                        placeholder="Search here"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <div className="input-group-append">
                        <button
                          type="button"
                          className="input-group-text input-group-text-rev "
                          onClick={() => setSearch("")}
                        >
                          {search ? (
                            <img
                              src="./assets/images/close.png"
                              alt=""
                              style={{ height: "30px" }}
                            />
                          ) : (
                            <i className="flaticon-381-search-2" />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                  <h3 class="py-3 px-5">Staking</h3>
                  <hr />
                  <div className="table-responsive">
                    <table
                      id="example5"
                      className="table table-striped patient-list mb-4 dataTablesCard fs-14"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Hash</th>
                          <th>Stake($)</th>
                          <th>Date</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data && data?.length
                          ? data.map((e, i) => {
                              let ind =
                                currentPage * pageSize - pageSize + (i + 1);
                              return (
                                <tr>
                                  <td>{ind}</td>
                                  <td className="text-primary">
                                    <a
                                      target="_blank"
                                      className="text-primary"
                                      href={`${process.env.REACT_APP_EXPLORER}/tx/${e.hash}`}
                                      rel="noreferrer"
                                    >
                                      {formatAddress(e.hash)}
                                    </a>
                                    {e.hash ? <Copy data={e.hash} /> : ""}
                                  </td>
                                  <td>$ {e.amount ? e.amount : "0"}</td>
                                  <td>
                                    {(e.createdAt)}
                                  </td>
                                 
                                  <td>
                                    <div className="d-flex align-items-center">
                                      {e.status === 0 ? (
                                        <>
                                          <div className="green-dot-active mx-2"></div>
                                          Active
                                        </>
                                      ) : (
                                        <>
                                          <div className="red-dot-active mx-2"></div>
                                          Deactive
                                        </>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          : ""}
                      </tbody>
                    </table>
                    {loading ? (
                      <div className="text-center">
                        <p>Loading...</p>
                      </div>
                    ) : !data || !data.length ? (
                      <div className="text-center">
                        <p>No data found.</p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    pages={pages}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </>
  )
}
