import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import axios from "axios";
import { GMCContext } from "../GMCContext";

const ProtecteRoutes = () => {
  const { token } = useContext(GMCContext);
  const [status, setStatus] = useState(true);
  const location = useLocation();

  const verifyJWTToken = async () => {
    try {
      if (localStorage.getItem("mark#321")) {
        await axios
          .post(process.env.REACT_APP_BACKEND_LINK, {
            method: "verify",
            submethod: "jwttoken",
            key: process.env.REACT_APP_KEY,
            token: localStorage.getItem("mark#321") || token,
          })
          .then((res) => {
            if (
              res.data?.status &&
              (res.data?.status === true || res.data?.status === "true")
            ) {
              setStatus(true);
            } else {
              setStatus(false);
            }
          });
      } else {
        setStatus(false);
      }
    } catch (err) {
      console.log("err : ", err);
      setStatus(false);
    }
  };

  useEffect(() => {
    if (token) {
      verifyJWTToken();
    } else {
      setStatus(false);
    }
  });

  return status ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default ProtecteRoutes;
