import React, { useContext, useEffect, useState } from "react";
import Header from "../Comman/Header";
import Sidebar from "../Comman/Sidebar";
import Footer from "../Comman/Footer";
import axios from "axios";
import { GMCContext } from "../../GMCContext";
import { Link } from "react-router-dom";
import Pagination from "../Comman/Pagination";
import Copy from "../Comman/Copy";
import Web3 from "web3";

export default function PendingMembers() {
  const { formatAddress, settings, toastSuccess, toastError } = useContext(GMCContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const [editAddress, setEditAddress] = useState("");
  const [bonusIncomeModal, setBonusIncomeModal] = useState(false);
  const [bonusIncomeErr, setBonusIncomeErr] = useState(false);
  const [bonusInc, setBonusInc] = useState(false);
  const [bonusPer, setBonusPer] = useState(0);
  const [bonusAmt, setBonusAmt] = useState(0);

  const [account, setAccount] = useState(null);
  const [web3c, setWeb3c] = useState(null);
  const [value, setValue] = useState(0);
  const [trdone, settrdone] = useState(true);
  const [selected_address, setselected_address] = useState();
  const [selected_reff_address, setselected_reff_address] = useState();
  const [selected_pacakge, setselected_pacakge] = useState();
  const [selected_pacakge_as, setselected_pacakge_as] = useState();

  const connectMetaMask = async () => {
    if (window.ethereum) {
      // MetaMask exists
      try {
        // Request account access if needed
        const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
        const web3Instance = new Web3(window.ethereum);

        // Set the web3 instance and the user's account
        setWeb3c(web3Instance);
        setAccount(accounts[0]);

        console.log("Connected account:", accounts[0]);
      } catch (error) {
        console.error("User denied account access or error occurred:", error);
      }
    } else {
      // MetaMask is not installed
      alert("Please install MetaMask to use this app.");
    }
  };

  const AddAddressMultiple = async (selected_address, selected_reff_address, selected_pacakge) => {
    // console.log("account", account);
    settrdone(false);
    var contract = new web3c.eth.Contract(JSON.parse(settings.trade_contract_abi), settings.trade_contract);

    console.log("logsss", selected_address, selected_reff_address, typeof (selected_pacakge));

    // Validate the selected_pacakge to ensure it's a valid uint8
    // if (isNaN(selected_pacakge) || selected_pacakge < 0 || selected_pacakge > 255) {
    //     toastError("Invalid package ID. It should be a number between 0 and 255.");
    //     return;
    // }

    // Validate the addresses
    if (!web3c.utils.isAddress(selected_address) || !web3c.utils.isAddress(selected_reff_address)) {
      toastError("Invalid Ethereum address.");
      return;
    }
    console.log("Number(selected_pacakge) === 4", Number(selected_pacakge) === 4)
    if (Number(selected_pacakge) === 4) {
      setselected_pacakge_as(Number(selected_pacakge) + 1)
      console.log("selected_pacakge", selected_pacakge_as)
    } else if (Number(selected_pacakge) === 3) {
      setselected_pacakge_as(Number(selected_pacakge) + 3)
      console.log("selected_pacakge", selected_pacakge_as)
    }
    else {
      setselected_pacakge_as(Number(selected_pacakge) - 1)
      console.log("selected_pacakge", selected_pacakge_as)
    }


    try {
      // Execute the contract method
      await contract.methods.addUser(selected_address, selected_reff_address, selected_pacakge_as).send({ from: account, gasPrice: 10000000 })
        .then(async res => {
          console.log("Ress", res);

          await axios.post(process.env.REACT_APP_BACKEND_LINK, {
            method: "admin",
            submethod: "insert",
            key: process.env.REACT_APP_KEY,
            hash: res.transactionHash, // You can use the actual transaction hash,
            address: selected_address,
            reff_address: selected_reff_address,
            pacakge: Number(selected_pacakge)
          })
            .then((res) => {
              getData();
              setselected_address("");
              setselected_reff_address("");
              setselected_pacakge("");
              if (res.data.error) {
                return "";
              }
              toastSuccess("Transaction Done");
            });
        });
    } catch (err) {
      console.error("Error in sending transaction:", err);
      toastError("Transaction failed.");
    }

    settrdone(true);
  };


  const getData = async () => {
    try {
      setLoading(true);
      await axios
        .post(process.env.REACT_APP_BACKEND_LINK, {
          method: "admin",
          submethod: "get",
          key: process.env.REACT_APP_KEY,
          search: search,
          page: currentPage,
          pageSize: pageSize,
        })
        .then((res) => {
          setLoading(false);
          if (res.data.error) {
            return "";
          }
          setdata(res.data.data);
          setPages(Math.ceil(res.data.dataLength / pageSize));
        });
    } catch (err) {
      console.log("error : ", err);
      setLoading(false);
    }
  };
  // console.log("data:", data)
  useEffect(() => {
    if (search) {
      setCurrentPage(1);
    }
    getData();
  }, [pages, pageSize, currentPage, search]);

  const editBonus = (address = "", status = false, dollar = 0, per = 0) => {
    setEditAddress(address);
    setBonusInc(status);
    setBonusAmt(dollar);
    setBonusPer(per);
    setBonusIncomeModal(true);
  };

  const bonusIncomeModalClose = () => {
    setEditAddress("");
    setBonusInc(false);
    setBonusAmt(0);
    setBonusPer(0);
    setBonusIncomeModal(false);
  };

  const saveBonusIncome = async () => {
    try {
      if (editAddress) {
        if (bonusAmt > 0) {
          if (bonusPer > 0) {
            await axios
              .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "bonusincome",
                submethod: "insert",
                status: bonusInc,
                bonus_per: bonusPer,
                locked_dollar: bonusAmt,
                address: editAddress,
                key: process.env.REACT_APP_KEY,
              })
              .then((res) => {
                if (res.data.error) {
                  setBonusIncomeErr(res.data.error);
                } else {
                  setEditAddress("");
                  setBonusAmt(0);
                  setBonusPer(0);
                  setBonusInc(false);
                  setBonusIncomeModal(false);
                  setBonusIncomeErr("");
                }
                getData();
              });
          } else {
            setBonusIncomeErr(
              "Bonus assigned percentage must be grather than 0."
            );
          }
        } else {
          setBonusIncomeErr("Dollar lock amount must be grather than 0.");
        }
      } else {
        setBonusIncomeErr("Address not found.");
      }
    } catch (err) {
      console.log("error : ", err);
      setBonusIncomeErr(err.message);
    }
  };
  useEffect(() => {
    console.log("selected_address", selected_address);
    console.log("selected_reff_address", selected_reff_address);
    console.log("selected_pacakge", selected_pacakge);
    // console.log("selected_address_amt_as", selected_address_amt_as);

  }, [selected_address])
  return (
    <>
      <div id="main-wrapper">
        <Header />
        <Sidebar />
        <div className="content-body">
          <div className="container-fluid">
            <div className="page-titles">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li className="breadcrumb-item active text-primary">
                  Pending Users
                </li>
              </ol>
            </div>
            <div className="d-flex  pe-5">
              <div>
                <h5 class="py-3 px-5 text-break">🟢{account ? formatAddress(account) : "Not Connected"}</h5>
                {/* <h5 class="px-5">Total: {value} {process.env.REACT_APP_TICKER}</h5> */}
                {/* <p class="px-5">
                  <input className="p-5" type="checkbox" name={`chkbox`} id={`chkbox`} onChange={(ed) => selectAll(ed)} /> Select All</p> */}
              </div>
              <div>
                {/* {account ? */}
                {/* // <>{trdone ?
                  //   <button className="btn btn-success m-1" onClick={() => AddAddressMultiple()}>
                  //     Add to Polygon
                  //   </button> : <img
                  //     src="/assets/images/loader.gif"
                  //     style={{ height: "80px" }}
                  //     alt="icon"
                  //   />
                  // }
                  // </> :
                  // } */}
              </div>
            </div>
            <button className="btn btn-success m-1" onClick={() => connectMetaMask()}>
              Connect Metamask
            </button>
            <hr />
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body p-0">
                    <div className="form-head d-flex align-items-center">
                      <div className="input-group search-area d-inline-flex m-3">
                        <input
                          type="text"
                          className="form-control form-control-rev"
                          placeholder="Search here"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        <div className="input-group-append">
                          <button
                            type="button"
                            className="input-group-text input-group-text-rev"
                            onClick={getData}
                          >
                            <i className="flaticon-381-search-2" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <h3 class="py-3 px-5">Pending Users</h3>
                    <hr />
                    <div className="table-responsive">
                      <table
                        id="example5"
                        className="table table-striped patient-list mb-4 dataTablesCard fs-14"
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Hash</th>
                            <th>Address</th>
                            <th>Reff. Address</th>
                            {/* <th>Reward Eligibility</th> */}
                            <th>Stake($)</th>
                            <th>Status</th>
                            {/* <th>CreatedAt</th> */}
                            {account ?
                              <th>Actions</th>
                              : ""}
                          </tr>
                        </thead>
                        <tbody>
                          {data && data?.length
                            ? data.map((e, i) => {
                              let ind =
                                currentPage * pageSize - pageSize + (i + 1);
                              return (
                                <tr>
                                  <td>
                                    {ind}
                                    {/* {ind} < input type="checkbox" name={`chkbox${i}`} id={`chkbox${i}`} onChange={(ed) => handleChange(ed, e.address, e.ref_address, e.bot_name, e.bot_pkg_amount)} /> */}
                                  </td>
                                  <td className="text-primary">
                                    <a
                                      target="_blank"
                                      className="text-primary"
                                      href={`${process.env.REACT_APP_EXPLORER}/tx/${e.hash}`}
                                      rel="noreferrer"
                                    >
                                      {formatAddress(e.hash)}
                                    </a>
                                    {e.hash ? <Copy data={e.hash} /> : ""}
                                  </td>
                                  <td>
                                    <a
                                      target="_blank"
                                      className="text-primary"
                                      href={`${process.env.REACT_APP_EXPLORER}/address/${e.address}`}
                                      rel="noreferrer"
                                    >
                                      {formatAddress(e.address)} {" "}{e.bot_name}
                                    </a>
                                    {e.address ? (
                                      <Copy data={e.address} />
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>
                                    <a
                                      target="_blank"
                                      className="text-primary"
                                      href={`${process.env.REACT_APP_EXPLORER}/address/${e.ref_address}`}
                                      rel="noreferrer"
                                    >
                                      {formatAddress(e.ref_address)}
                                    </a>
                                    {e.ref_address ? (
                                      <Copy data={e.ref_address} />
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  {/* <td>{e.income_capability}X</td> */}
                                  <td>$ {e.amount}</td>
                                  <td>
                                    {e.status === 1 ? "Active" : "Over"}
                                  </td>
                                  <td>
                                    {account ?
                                      <>{trdone ?
                                        <button
                                          className="btn btn-success m-1" id={ind}
                                          onClick={() => AddAddressMultiple(e.address, e.ref_address, e.bot_name)}
                                        >
                                          Add to Arbiscan
                                        </button>
                                        : <img
                                          src="/assets/images/loader.gif"
                                          style={{ height: "80px" }}
                                          alt="icon"
                                        />
                                      }
                                      </> :
                                      ""}
                                  </td>
                                </tr>
                              );
                            })
                            : ""}
                        </tbody>
                      </table>
                      {loading ? (
                        <div className="text-center">
                          <p>Loading...</p>
                        </div>
                      ) : !data || !data.length ? (
                        <div className="text-center">
                          <p>No data found.</p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      pages={pages}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
